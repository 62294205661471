import { useEffect, useState } from 'react';
import { useIsGAppUserAgent } from './useIsGAppUserAgent';
import { useWindowDimensions } from './useWindowDimensions';

const SM = 640;
const MD = 768;
const LG = 1024;

type Result = {
  isBase: boolean;
  isSM: boolean;
  isLtMd: boolean;
  isMD: boolean;
  isLG: boolean;
  isLessThanMD: boolean;
};

// NOTE: this tries to act as close to tailwind breakpoints
// as possible, that is why we don't have checks like
// MD < isMD < LG and instead use MD < isMD only
const useDevice = (): Result => {
  const isGapp = useIsGAppUserAgent();
  const { width } = useWindowDimensions();
  const [isBase, setIsBase] = useState<boolean>(true);
  const [isSM, setIsSM] = useState<boolean>(false);

  const [isLtMd, setIsLtMd] = useState<boolean>(true);
  const [isMD, setIsMD] = useState<boolean>(false);

  const [isLG, setIsLG] = useState<boolean>(false);
  const [isLessThanMD, setIsLessThanMD] = useState<boolean>(false);

  useEffect(() => {
    setIsBase(!!(width && width < SM));
    setIsSM(!!(width && width >= SM));
    setIsLtMd(!!(width && width < MD));
    setIsMD(!!(width && width >= MD));
    setIsLG(!!(width && width >= LG));
    setIsLessThanMD(!!(width && width < MD));
  }, [width]);

  if (isGapp) {
    return {
      isBase: true,
      isSM: false,
      isLtMd: true,
      isMD: false,
      isLG: false,
      isLessThanMD: true,
    };
  }

  return { isBase, isSM, isLtMd, isMD, isLG, isLessThanMD };
};

export { useDevice };
